import React from 'react'
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from 'framer-motion'

import Main from "components/Main/Main"

function AnimatedRoutes() {

    const location = useLocation();

    return (
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route exact path="/" element={<Main />} />
                <Route exact path="*" element={<Main />} />
            </Routes>
        </AnimatePresence>
    )
}

export default AnimatedRoutes