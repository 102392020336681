import React, { useState, useEffect, useRef } from 'react';

import { motion } from 'framer-motion'

import styled from "styled-components";

// const props = {
//     metabeyond: "500px"
// }




const Navbar = ({ styleProps, navbarShow }) => {



    // useEffect(() => {
    //     // console.log("Navnar.js > useEffect > navbarShow=" + navbarShow)


    //     // if (navbarShow === true) {
    //     //     SetPreContainerActive('active')
    //     //     SetPreContainerCurrentHeight(0 - PreContainerRef.current.getBoundingClientRect().height)
    //     //     console.log("Navbar.js > PreContainerRef.current.getBoundingClientRect().top === " + preContainerCurrentHeight)
    //     // } else {
    //     //     SetPreContainerActive('')
    //     //     SetPreContainerCurrentHeight(0 - PreContainerRef.current.getBoundingClientRect().height)
    //     //     console.log("Navbar.js > PreContainerRef.current.getBoundingClientRect().top === " + preContainerCurrentHeight)
    //     // }
    // }, [navbarShow]);




    return (
        <PreContainer
            className={navbarShow ? 'precontainer-active' : ''}
        >
            <Container>
                {/* <PopupTop><p>Warning! You are missing our newest product!</p></PopupTop> */}
                <Bar>
                    <LogoUL>
                        <LogoLIName href="#">roma Korobko</LogoLIName>
                        <LogoLIJob href="#">DOP</LogoLIJob>
                    </LogoUL>
                    <MenuUL>
                        <MenuLI href="https://youtu.be/DEilIwPNwXE" target="_blank">showreel</MenuLI>
                        <MenuLI href="https://instagram.com/rokofo" target="_blank">ig</MenuLI>
                        {/* <MenuLI href="/">Commercial</MenuLI> */}
                        {/* <MenuLI href="/">Feature</MenuLI> */}
                        <MenuLI href="./content/CV/Roman_Korobko_Filmography_20230206.pdf" target="_blank">cv</MenuLI>
                        <MenuLI href="https://www.kinopoisk.ru/name/4249692">кинопоиск</MenuLI>
                        <MenuLI href="https://www.imdb.com/name/nm10645407">imdb</MenuLI>
                        <MenuLI href="#contacts">сontact</MenuLI>
                    </MenuUL>
                </Bar>

            </Container>
        </PreContainer>
    )
};



///////////
///////////
///////////
// console.log("navbarShow === " + navbarShow)

const PreContainer = styled.section`
        position: fixed;
        width: 100%;
        z-index: 2;

        /* backdrop-filter: blur(5px); */
        /* background-color: hsl(0 0% 0% / 33%); */
        background: linear-gradient(0deg, hsl(0 0% 0% / 0%) 0%, hsl(0 0% 0% / 66%) 100%);

        transition: all .5s;
        /* top: -35px; */
        visibility: hidden;
        opacity: 0;
        &.precontainer-active {
            /* background-color: cyan; */
            /* top: 0; */
            visibility: visible;
            opacity: 1;
            transition: all 1s;
        }

        /* font-size: calc(0.8vw + 0.2em); */
        font-size: calc(0.7vw + 0.5em);
        @media only screen and (max-width: 1300px) {
            top: -35px !important;
            visibility: hidden !important;
            opacity: 0 !important;
        }
    `;


const Container = styled.section`
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
        /* justify-content: space-between; */
        /* justify-content: center; */

    `;

const PopupTop = styled.div`
        /* background-color: black; */
        /* color: white; */
        
        width: 100%;
        display: flex;
        justify-content: center;
        p {
            margin: 0.75em 0;
        }
    `

const Bar = styled.div`
        /* background-color: hsl(270 100% 50% / 25%); */
        /* height:50px; */
        /* height: 3em; */
        height: calc(1vw + 50px);
        width: 100%;
        padding: 0 1em;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;       
        @media only screen and (max-width: 535px) {
            flex-flow: column nowrap;
        }
        transition: opacity .3s;
        /* backdrop-filter: blur(1000px); */
        /* background-color: hsl(0 0% 0% / 15%); */

        /* backdrop-filter: blur(5px); */
        /* background-color: hsl(0 0% 0% / 90%); */
    `

const LogoUL = styled.div`
        /* background-color: hsl(90 100% 50% / 50%); */
        display: flex;
        flex-flow: row nowrap;
    `;
const LogoLIName = styled.a`
        /* background-color: hsl(0 100% 50% / 25%); */
        text-transform: uppercase;
        margin:0 1em;
        margin-right: 0.25em;
    `
const LogoLIJob = styled.a`
        /* background-color: hsl(0 100% 50% / 25%); */
        margin:0 1em;
        margin-left: 0.25em;
        font-weight: 400;
    `

const MenuUL = styled.div`
        /* background-color: hsl(90 100% 50% / 50%); */
        
        display: flex;
        flex-flow: row nowrap;
        
    `;
const MenuLI = styled.a`
        /* background-color: hsl(0 100% 50% / 25%); */
        margin:0 1em;
        
        opacity: .6;
        :hover {
            opacity: 1;
        }
    `



///////////
///////////
///////////



export default Navbar;