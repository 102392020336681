import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
// In react-router-dom v6, "Switch" is replaced by routes "Routes". https://stackoverflow.com/questions/63124161/attempted-import-error-switch-is-not-exported-from-react-router-dom

import "./App.css";
import AnimatedRoutes from "components/AnimatedRoutes/AnimatedRoutes";


function App() {



  return (
    <div className="App">
      <Router>
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;